// @font-face {
//   font-family: SFProDisplay;
//   src: url(assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf)
//     format('opentype');
// }

@font-face {
  font-family: 'SFProDisplay-Light';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/SFProDisplay-Light.ttf');
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/SFProDisplay-Regular.ttf');
}

@font-face {
  font-family: 'SFProDisplay-Medium';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/SFProDisplay-Medium.ttf');
}

@font-face {
  font-family: 'SFProDisplay-Semibold';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('assets/fonts/SFProDisplay-Semibold.ttf');
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('assets/fonts/SFProDisplay-Bold.ttf');
}
