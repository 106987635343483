/* You can add global styles to this file, and also import other style files */
@import './styleFonts.scss';
@import './stylesColors.scss';
@import './styles/modal.scss';

* {
  font-family: 'SFProDisplay-Regular';
  font-style: normal;
}

html body {
  background-color: var(--background);
}

h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: var(--agreyishGalisur);
}

h2 {
  //font-weight: ;
  font-size: 32px;
  line-height: 40px;
  color: var(--agreyishGalisur);
}

h6 {
  font-family: 'SFProDisplay-Semibold';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2%;
  color: var(--agreyishGalisur);
}

p {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--agreyishGalisur);
}

button {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 2%;

  &:hover {
    background-color: var(---cerulean100Galisur);
    color: var(---whiteTwoGalisur);
  }
}

.tableHeader {
  background: var(--cerulean100Galisur);
  opacity: 0.2;
}

// Ngx-bootstrap
.modal-dialog {
  min-width: 750px;

  .modal-content {
    max-width: 100%;
  }

  .modal-footer {
    .btn-primary {
      min-width: 115px;
      border-color: var(--cerulean100Galisur);
      background-color: var(--cerulean100Galisur);
      font-family: SFProDisplay-Semibold;
    }
  }
}

.bodyM {
  font-family: 'SFProDisplay-Regular';
  font-size: 16px;
  line-height: 20px;
}

.bodyS {
  font-family: 'SFProDisplay-Regular';
  font-size: 14px;
  line-height: px;
}

// ngx-bootstrap - Tabset profile-creation
#profileCreationTabset,
.custom-tabset {
  .nav-tabs,
  .nav-tabs:hover {
    border: none;
  }

  .nav-tabs a {
    color: var(--agreyishGalisur);
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    margin-right: 8px;
  }

  .nav-tabs .nav-link.active {
    border: none;
    background-color: #c9eaf3;
    color: #22b1d4;

    .tab__separator {
      display: none;
    }
  }

  .nav-tabs .nav-link:hover {
    border: none;
    transform: none;
  }
}

// ngx-bootstarp areas accordion
.areasAccordion {
  border: none !important;
  border-top: 1px solid var(--cerulean20Galisur) !important;
  border-bottom: 1px solid var(--cerulean20Galisur) !important;

  .card-header {
    border-color: var(--cerulean20Galisur);
    background-color: var(--whiteGalisur);
  }
}

// ngx-bootstrap areas tooltip
.areaTooltip {
  // .bs-tooltip-top .arrow::before,
  // .bs-tooltip-auto[x-placement^='top'] .arrow::before {
  //   border-top-color: var(--greyishDark);
  // }
  .tooltip-inner {
    background-color: var(--greyishDark);
  }
}

html,
body {
  height: 100%;
}

.modal-frames {
  max-width: 100% !important;
  width: 80% !important;

  .modal-content {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .modal-frames {
    width: 70% !important;
  }
}

@media (min-width: 1400px) {
  .modal-frames {
    width: 60% !important;
  }
}

// Jonquillos
.modal-jonquillos {
  max-width: 100% !important;
  width: 80% !important;

  .modal-content {
    max-width: 650px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .modal-jonquillos {
    width: 70% !important;
  }
}

@media (min-width: 1400px) {
  .modal-jonquillos {
    width: 60% !important;
  }
}

.modal-dialog.modal-dialog-centered.modal-jonquillos.modal-lg {
  display: flex;
  justify-content: center;
}

// Openings
.modal-openings {
  max-width: 100% !important;
  width: 80% !important;

  .modal-content {
    max-width: 1000px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .modal-openings {
    width: 70% !important;
  }
}

@media (min-width: 1400px) {
  .modal-openings {
    width: 60% !important;
  }
}

.modal-dialog.modal-dialog-centered.modal-openings.modal-lg {
  display: flex;
  justify-content: center;
}

// Animation
.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Tabset bootstrap
.tab-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-title__text {
  padding: 8px;
}

.tab__separator {
  width: 1px;
  height: 20px;
  background-color: var(--agreyishGalisur);
}

.tab__separator.last {
  display: none;
}
