:root {
  --background: #f5f5f5;
  // basic
  --blackGalisur: #1d1d1d;
  --titlesGalisur: #c4c4c4;
  --btnColor: #c5c5c5;
  --modalBorder: #dee2e6;
  --greyishGalisur: #666666;
  --greyishDark: #4c4c4c;
  --agreyishGalisur: #b7b7b7;
  --whiteGalisur: #ffffff;
  --whiteTwoGalisur: #f5f5f5; //for disabled text
  --greyshRoundsButtonsMenu: #c4c4c4;
  // primary
  --primary: #1ba1cf;
  --cerulean100Galisur: #0196c9;
  --cerulean90Galisur: #1ba1cf;
  --cerulean50Galisur: #80cbe4;
  --cerulean20Galisur: #cceaf4;
  // securdary
  --coralGalisur: #f55145;
  --shamrockGalisur: #00b140;
  --pinkishGrayGalisur: #ddd8c2;
  --disabledField: #dfdfdf;
  --disabledButton: #c5c5c5;
}
