.modal-fullscreen {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  margin: 0 !important;

  & .modal-content {
    height: 100%;
    width: 100%;

    overflow-y: auto;

    & > * {
      height: 100%;
    }
  }
}
